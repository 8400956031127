import React from 'react'
import { makeStyles, Button, Typography, List, ListItem, Table, TableRow, TableCell } from '@material-ui/core'
import ReactGA from 'react-ga'

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // // backgroundColor: theme.palette.background.paper,
    // overflow: 'auto',
  },
  listHeader: {
    borderBottom: '2px solid',
  },
  listItem: {
    minHeight: '10vh',
    width: '100%',
  },
}))

export default function Listing({ requestedClinics, selectedFilter }) {
  const classes = useStyles()

  const sendRegistrationClickEvent = (clinicName) => {
    ReactGA.event({ category: 'Registration', action: `${clinicName} link click` })
  }

  return (
    <>
      <Typography variant='button' color='primary' className={classes.listHeader} align='center'>
        Clinics Currently Accepting Patients
      </Typography>
      <List component='nav' className={classes.root}>
        {selectedFilter === 'covidVac' && requestedClinics.length === 0 ? (
          <>
            <Typography variant='h3' align='center' color='primary' paragraph='true'>
              Sorry, no clinics are currently accepting <strong>Initial COVID Vaccination</strong> visits.
            </Typography>
            <Typography variant='h3' align='center' color='primary'>
              Please check back regularly to see availability.
            </Typography>
          </>
        ) : selectedFilter === 'general' && requestedClinics.length === 0 ? (
          <>
            <Typography variant='h3' align='center' color='primary' paragraph='true'>
              Sorry, our clinics have no General Availability (non-COVID Vaccination Visits).
            </Typography>
            <Typography variant='h3' align='center' color='primary'>
              Please check back regularly to see availability.
            </Typography>
          </>
        ) : (
          <>
            {requestedClinics?.map((clinic) => (
              <ListItem button className={classes.listItem} key={clinic.id}>
                <Table>
                  <TableRow>
                    <TableCell style={{ width: '25vw' }}>
                      <Typography variant='h5' color='primary'>
                        {clinic.name}
                      </Typography>
                      <Typography variant='body2' color='primary'>
                        {selectedFilter === 'covidVac' &&
                          (clinic.status.covidVaccineInfo.type === 'moderna' ? 'Moderna (Two Dose)' : 'J&J (One Dose)')}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.registerBtn}
                        onClick={(event) => {
                          event.preventDefault()
                          sendRegistrationClickEvent(clinic.name)
                          window.open(process.env.REACT_APP_CLINIC_REDIRECT + `${clinic.key}`, '_blank')
                        }}
                      >
                        Register
                      </Button>
                    </TableCell>
                  </TableRow>
                </Table>
              </ListItem>
            ))}
          </>
        )}
      </List>
    </>
  )
}
