import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Grid,
  Typography,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core'
import useSWR from 'swr'
import Listing from './components/Listing'
import ReactGA from 'react-ga'
import { crowDistance } from './helper/crowDistance'

const fetcher = (...args) => fetch(...args).then((res) => res.json())

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 210,
  },
}))

export default function App() {
  const [userCoords, setUserCoords] = useState()
  const [selectedFilter, setSelectedFilter] = useState('general')

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const patientReason = urlParams.get('reason')?.toLowerCase()

  const classes = useStyles()

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserCoords({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      })
    } else {
      console.log('geolocation not available')
    }
  }, [])

  useEffect(() => {
    ReactGA.initialize('UA-178172494-3')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    if (patientReason) {
      setSelectedFilter(patientReason)
    }
  }, [])

  const { data, error } = useSWR(process.env.REACT_APP_CLINICS_INFO_ENDPOINT, fetcher, {
    refreshInterval: 1000 * 60 * 1,
  })
  console.log(data)

  if (error)
    return (
      <Grid container direction='column' justify='center' alignItems='center'>
        <Card>
          <CardMedia image={process.env.PUBLIC_URL + '/MFCLogoCompressed.png'} title='MEDIA TITLE' />
          <CardContent className={classes.header}>
            <Typography color='primary' variant='h4'>
              We're sorry, but there was an error in your request. Please refresh or go back.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  if (!data)
    return (
      <div>
        <LinearProgress color='primary' />
      </div>
    )

  const { clinics } = data

  const openClinics = clinics.filter(
    (clinic) => clinic.status.isVehicleRegistrationOpen === true || clinic.status.isWaitingRoomRegistrationOpen === true
  )

  const filterClinicsOnNeed = (clinicList) => {
    if (selectedFilter === 'general') {
      return clinicList.filter((clinic) => clinic.status.hasVisitCapacity === true)
    } else if (selectedFilter === 'covidVac') {
      return clinicList.filter((clinic) => clinic.status.covidVaccineInfo.allowInitialCovidVaccine === true)
    } else {
      return clinicList
    }
  }

  const sortOnDistance = (clinicList) => {
    if (userCoords) {
      const clinicsWithDistance = clinicList.map((clinic) => ({
        ...clinic,
        distance: crowDistance(
          userCoords.latitude,
          userCoords.longitude,
          clinic.coordinates.latitude,
          clinic.coordinates.longitude
        ),
      }))
      return clinicsWithDistance.sort((a, b) => a.distance - b.distance)
    } else {
      return clinicList
    }
  }

  const requestedClinics = sortOnDistance(filterClinicsOnNeed(openClinics))

  return (
    <>
      <Grid container direction='column' alignItems='center' className={classes.root}>
        <Grid item>
          <Typography color='primary' align='center' variant='h2'>
            I am looking for...
          </Typography>
        </Grid>
        <Grid item container direction='column' alignItems='center'>
          <FormControl variant='outlined' className={classes.formControl}>
            <Select
              id='reasonFilterSelection'
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              style={{ width: '75vw' }}
            >
              <MenuItem value={'general'} style={{ whiteSpace: 'normal' }}>
                General Availability (non-COVID Vaccinations visits)
              </MenuItem>
              <MenuItem value={'covidVac'}>A COVID Vaccination</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item container direction='column' alignItems='center'>
          <Listing requestedClinics={requestedClinics} selectedFilter={selectedFilter} />
        </Grid>
      </Grid>
    </>
  )
}
